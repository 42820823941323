body.admin-view {
    fieldset {
        padding: 0;
    }
    label {
        margin-bottom: 0.5rem;
        font-weight: normal;
        @include fluid(16px, 18px);
    }
    input {
        margin-bottom: 1.5rem;
        width: 100%;
    }
    .input.radio,
    .input.checkbox {
        margin-bottom: 2rem;
    }
    .input.radio input,
    .input.checkbox input {
        margin: 0;
    }
    .input.radio label,
    .input.checkbox label {
        margin: 0;
        display: flex;
        align-items: center;
    }
    .input.radio label > input,
    .input.checkbox label > input {
        margin-right: 1rem;
    }
    .input.radio label:first-of-type {
        margin-bottom: 2rem;
    }
    .error-message,
    .error label {
        color: $red;
    }
    .error-message {
        margin-bottom: 1rem;
    }
    .checkbox .error-message {
        margin-top: 1rem;
    }
    input.form-error {
        border: 2px solid $red;
    }
    .required label:after {
        content: " *";
        color: $red;
    }
    textarea {
        margin-bottom: 1rem;
    }
    .input.select {
        margin-bottom: 2rem;
    }
    .checkbox label {
        margin-top: 1.5rem;
        input {
            margin-bottom: 0;
            margin-right: 1rem;
        }
    }
    form #order {
        max-width: 100px;
    }
    .input.error {
        background: $white;
        padding: 0;
    }

    &.offers.add,
    &.offers.edit {
        select {
            margin-bottom: 0;
        }
    }
    #loyaltyOfferImageContainer,
    #homepageOfferImage {
        margin: 3rem 0 2rem;
    }
}
