/*------------------------------------
 COLORS
------------------------------------*/
$white: #ffffff;
$black: #000000;
$red: #ed1c24;
$blue: #034ea2;
$lightBlue: #f3f4f9;
$darkBlue: darken(#034ea2, 10%);
$purple: #2f2c7d;
$green: #4bb543;
$yellow: #feefb3;
$darkYellow: #cfa500;
$brightYellow: #ffff00;
$gray: #a7aaad;
$darkGray: #606c76;
$lightGray: #cfd0d2;
