body.admin-view {
    .messageThreads.content {
        h3 {
            margin-bottom: 0;
        }
        h4 {
            margin-top: 0;
        }

        .message-thread {
            border-top: 2px solid $black;
            padding: 2rem 0;

            &:last-child {
                border-bottom: 2px solid $black;
            }

            .details {
                margin-bottom: 1rem;

                p {
                    margin-bottom: 0.5rem;
                }
            }
            .message-content {
                margin-top: 1rem;
            }
        }
    }
}
