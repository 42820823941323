/*------------------------------------
 VARIABLES
------------------------------------*/
$global-max-width: 1350px;

$breakpoints: (
	xsmall: 320px,
	small: 400px,
	medium: 678px,
	large: 1024px,
	xlarge: 1200px,
);

$global-padding: (
	xsmall: .25rem,
	small: .5rem,
	medium: .75rem,
	large: 1rem,
	xlarge: 2rem,
);

@mixin transition() {
    transition: all .3s ease-in-out;
}