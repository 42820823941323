/*------------------------------------
 BREAKPOINT MIXIN
------------------------------------*/

 @use "sass:math";

 $breakpoints: (
     xsmall: 0,
     small: 400px,
     medium: 768px,
     large: 1024px,
     xlarge: 1200px,
 ) !default;

 @mixin breakpoint($breakpoint, $modifier: "up") {
     $breakpoint-value: "";

     // Check to see if a breakpoint key was passed in
     @if map-has-key($breakpoints, $breakpoint) {
         $breakpoint-value: map-get($breakpoints, $breakpoint);
     } @else {
         $breakpoint-value: $breakpoint;
     }

     @if ($modifier == down) {
         @media (max-width: $breakpoint-value) {
             @content;
         }
     } @else {
         @media (min-width: $breakpoint-value) {
             @content;
         }
     }
 }

 $breakpoint-aliases: (
     xs: map-get($breakpoints, xsmall),
     s: map-get($breakpoints, small),
     m: map-get($breakpoints, medium),
     l: map-get($breakpoints, large),
     xl: map-get($breakpoints, xlarge),
 );

 @mixin mq($breakpoint, $modifier: up) {
     $breakpoint-value: "";

     // Checks against breakpoint aliases but also works with the full variable name as well
     @if map-has-key($breakpoint-aliases, $breakpoint) {
         $breakpoint-value: map-get($breakpoint-aliases, $breakpoint);
     } @else {
         $breakpoint-value: $breakpoint;
     }

     @include breakpoint($breakpoint-value, $modifier) {
         @content;
     }
 }
