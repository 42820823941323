body.admin-view {
    .side-nav {
        .side-nav-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 0.5rem;
        }
        svg.checkMarkIcon,
        svg.editIcon,
        svg.deleteIcon,
        svg.viewIcon,
        svg.listIcon,
        svg.addIcon {
            width: 20px;
            height: 20px;
            margin-right: 1.2rem;
            path {
                fill: $green;
            }
        }
        svg.viewIcon {
            margin-right: 2px;
            top: 1px;
            position: relative;
            path {
                fill: $black;
            }
        }
        svg.deleteIcon path {
            fill: $red;
        }
        svg.editIcon path,
        svg.listIcon path {
            fill: $darkBlue;
        }
    }
}
