.app-install {
    max-width: 550px;
    margin: 0 auto;
    padding: 0 2rem;

    #logo {
        max-width: 300px;
        width: 100%;
        display: block;
        margin: 4rem auto 0;

        @include breakpoint(640px, up) {
            max-width: 640px;
        }

        img {
            max-width: 95%;
            margin: 0 auto;
            display: block;
        }
    }

    h1,
    h2,
    h3,
    h4 {
        color: $white;
    }
    h1 {
        margin-bottom: 0;
        text-align: center;
    }
    h2 {
        margin-top: 2rem;
        text-align: center;
    }
    p {
        color: $white;
    }
    h3 {
        display: flex;
        flex-direction: row;
        align-content: center;
        margin-bottom: 1rem;
        text-transform: uppercase;

        span {
            display: inline-block;
            margin-left: 2rem;
        }

        &.apple-headline span {
            padding-top: 1rem;
        }
    }
    h4 {
        margin-bottom: 1rem;
        text-transform: uppercase;
    }

    a {
        color: $white;
        @include transition;
        &:hover {
            color: $lightGray;
        }
    }

    svg {
        width: 30px;

        &.ios-icon,
        &.ios-share,
        &.android-icon,
        &.ellipsis {
            width: 30px;

            path {
                fill: $white;
            }
        }
        &.ios-share {
            width: 45px;
            margin-top: 1rem;
        }
        &.android-icon {
            width: 40px;
        }
        &.ellipsis {
            width: auto;
            height: 40px;
            margin-left: 1rem;
            margin-top: 1rem;
        }
    }
    p.note {
        font-style: italic;
        font-size: 1.6rem;
    }
    .iosContainer ~ .androidContainer {
        margin-top: 6rem;
    }

    .no-thanks {
        font-style: italic;
        margin-top: 4rem;
        margin-bottom: 6rem;

        a.button {
            width: auto;
            max-width: 100%;
            padding: 0;
        }
    }
}
