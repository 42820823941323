#pushNotificationsOptin {
    position: fixed;
    top: 2rem;
    right: 0;
    box-shadow: 4px 3px 5px $black;
    background: $white;
    border-radius: 10px;
    z-index: 100;
    padding: 2rem;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-delay: 1.5s;
    max-width: 300px;
    font-size: 18px;
    p {
        margin-bottom: 1rem;

        &.small-text {
            font-size: 1.6rem;
        }
    }

    &.active {
        -webkit-animation-name: slideInRight;
        animation-name: slideInRight;
    }
    &.inactive {
        -webkit-animation-name: slideOutRight;
        animation-name: slideOutRight;
        animation-delay: 0s;
    }
    &.hidden {
        visibility: hidden;
    }

    button {
        font-size: 1.6rem;
        margin-bottom: 1rem;
        padding-right: 1rem;
        padding-left: 1rem;

        &.blue {
            background: $blue;
            border: 2px solid $blue;
            margin-right: 1rem;
            @include transition;

            &:hover {
                background: $darkGray;
            }
        }
        &.gray {
            background: $darkGray;
            border: 2px solid $darkGray;
            @include transition;

            &:hover {
                background: lighten($darkGray, 10%);
            }
        }
    }
}

@-webkit-keyframes slideInRight {
    0% {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
        margin-right: 0;
    }
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        margin-right: 2rem;
    }
}
@keyframes slideInRight {
    0% {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
        margin-right: 0;
    }
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        margin-right: 2rem;
    }
}

@-webkit-keyframes slideOutRight {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}
@keyframes slideOutRight {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}
