body.admin-view {
    .offers.index,
    .altriaApiLogs.index,
    .messageThreads.index {
        h4 {
            margin-bottom: 1rem;
            width: 100%;
        }
        .filter-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            align-items: flex-start;
            margin-bottom: 2rem;
            div {
                width: 33%;
                max-width: 200px;
                margin-right: 2rem;
            }
            div.submit {
                width: auto;
                margin-top: 8px;
            }
            .submit input {
                margin-bottom: 0;
            }
            .date-range-filter {
                width: 100%;
                display: flex;
                max-width: 100%;
                flex-wrap: wrap;

                div.input.checkbox {
                    width: 100%;
                    max-width: 100%;
                }
                .show-dates {
                    display: none;
                    flex-direction: row;
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
}
