/*------------------------------------
 TYPOGRAPHY
------------------------------------*/
@use "../variables/colors";
@use "../mixins/fluid-typography";

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&family=Rokkitt:wght@700&display=swap");

$header-font-family: "Rokkitt", serif;
$header-line-height: 1.2;
$header-default-color: $black;
$header-font-weight: 700;

$body-font-family: "Roboto", sans-serif;
$body-line-height: 1.2;
$body-default-color: $black;
$body-font-weight: 400;

// Base header styles
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $header-font-family;
    font-weight: $header-font-weight;
    line-height: $header-line-height;
    color: $header-default-color;
}

h1,
.fluid-h1 {
    @include fluid(30px, 36px);
    color: $darkBlue;
}
h2,
.fluid-h2 {
    @include fluid(26px, 30px);
    color: $darkBlue;
}
h3,
.fluid-h3 {
    @include fluid(20px, 26px);
}
h4,
.fluid-h4 {
    @include fluid(18px, 20px);
}
h5,
.fluid-h5 {
    @include fluid(16px, 18px);
}
h6,
.fluid-h6 {
    @include fluid(14px, 16px);
}
.fluid-sm {
    @include fluid(12px, 16px);
}
.fluid-xs {
    @include fluid(8px, 10px);
}

body {
    color: $body-default-color;
    font-family: $body-font-family;
    font-weight: $body-font-weight;
    line-height: $body-line-height;
    @include fluid(16px, 20px);
}
