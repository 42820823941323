table {
    th, td {
        min-height: 50px;
        &:last-child {
            min-width: 120px;
        }
    }
    td.icon {
        text-align: center;
    }
    svg.checkMarkIcon, svg.editIcon, svg.deleteIcon, svg.viewIcon, svg.listIcon, svg.addIcon, svg.externalLinkIcon, svg.exclamation, svg.messageIcon, svg.closeIcon {
        width: 20px;
        height: 20px;
        path {
            fill: $green;
        }
    }
    svg.viewIcon {
        margin-right: 2px;
        top: 1px;
        position:relative;
        path {
            fill: $black;
        }
    }
    svg.deleteIcon path, svg.exclamation path, svg.closeIcon path {
        fill: $red;
    }
    svg.editIcon path, svg.listIcon path, svg.externalLinkIcon path, svg.messageIcon path {
        fill: $darkBlue;
    }
}