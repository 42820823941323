html {
    --scroll-behavior: smooth;
    scroll-behavior: smooth;
    overscroll-behavior-x: none;
}
body {
    background: $blue;
    padding: 0;
    overflow-x: hidden;
    line-height: 1.5;
}

.hidden {
    display: none;
}
.message.success,
.message.warning,
.message.error,
.message.hint {
    margin: 0 auto;
}
[data-lastpass-root=""] + img {
    display: none;
}
.app-view {
    max-width: $global-max-width;
    margin: 0 auto;
    width: 100%;
    padding: 0;
    @include breakpoint(1350px, up) {
        padding: 0 2rem;
    }

    .container {
        max-width: 1350px;
        padding: 0;
    }
}
.button.float-right {
    margin-left: 1rem;
}
.center {
    text-align: center;
}

#appView {
    .content {
        padding: 1rem 1rem;

        @include breakpoint(640px, up) {
            padding: 1rem 2rem;
        }
        @include breakpoint(1024px, up) {
            padding: 2rem;
        }
        border-radius: 0;
        min-height: calc(100vh - 125px);
        background: none;
        box-shadow: none;
    }
    .swiper-container {
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 250px;
        height: 143px;
    }
    .products-slide {
        background-position: center;
        background-size: contain;
        height: auto;
        width: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
        img {
            max-height: 200px;
            margin-bottom: 0;
        }
    }
    .featured-homepage-products {
        margin: 4rem auto 2rem;
    }
    h1.title {
        color: $white;
        text-align: center;
        margin-top: 0;
    }
    h2 {
        color: $white;
        text-align: center;
        margin-bottom: 1rem;
    }
    .button {
        color: $white;
        background: $red;
        text-transform: uppercase;
        padding: 0.5rem 2rem 0.5rem;
        margin: 0 auto;
        @include transition;

        &:hover {
            background: $darkGray;
        }
        &.space {
            margin-bottom: 2rem;
        }
    }
    .lottery-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 2rem;

        h3 {
            margin: 0;
            text-align: center;
            color: $blue;
        }
        p {
            margin: 0 0 1.5rem 0;
            color: $darkGray;
        }

        .lottery-box {
            padding: 2rem;
            background: $white;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            width: 100%;
            margin-bottom: 1rem;

            @include breakpoint(640px, up) {
                width: 49%;
            }
        }
    }
    .contact-form {
        div.mui-input-group {
            margin: 0 auto;
        }
        .submit {
            margin: 0 auto;
            position: relative;
            width: 100%;
            max-width: 400px;

            input {
                margin: 0 auto;
            }
        }
    }

    .digital-loyalty-card {
        min-width: 320px;
        width: 100%;
        max-width: 640px;
        padding: 2rem;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 10px;
        margin: 0 auto;
        background: $white;

        img {
            width: 100%;
            height: 50px;
            margin: 0 auto;
            display: block;
        }
        p {
            text-align: center;
            font-weight: bold;
            margin-top: 2rem;
            color: $darkGray;
            font-size: 3rem;
        }
    }

    .message-count-header {
        font-size: 3rem;
        span {
            width: 100%;
            display: block;
            font-size: 2.5rem;
        }
    }
    .message-separator {
        height: 2px;
        background: $lightGray;
        width: 100%;
    }

    .message-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        background: $white;
        padding: 1rem 3rem;

        &.unread {
            background: lighten($lightGray, 40%);
            border-left: 8px solid $red;
        }

        .message-content {
            width: 75%;

            h3 {
                color: $blue;
                margin: 0;
                text-align: left;
                font-family: $body-font-family;
            }
            p {
                color: $darkGray;
                font-size: 1.6rem;
            }
        }
        .action {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            width: 25%;
            a {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-left: 2rem;
            }
            .message-delete,
            .message-mark-read,
            .message-mark-unread {
                color: $red;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 1.2rem;

                svg {
                    width: 20px;
                    margin-bottom: 0.5rem;
                    path {
                        fill: $red;
                    }
                }
            }
            .message-mark-read {
                color: $green;
                svg {
                    width: 25px;
                    path {
                        fill: $green;
                    }
                }
            }
        }
    }

    .pagination {
        margin-top: 2rem;
        a {
            color: $white;
            @include transition;
            &:hover {
                color: darken($white, 10%);
            }
        }
        li.active a {
            color: $gray;

            &:hover {
                color: $lightGray;
            }
        }
    }

    .message-content {
        background: $white;
        border-radius: 6px;
        padding: 2rem;

        h1.title {
            color: $blue;
        }
        .message-sep {
            height: 2px;
            width: 100%;
            background: $darkGray;
        }
        .message-container {
            width: 100%;
            border-bottom: 2px solid $lightGray;
            padding: 2rem 0;
            color: $darkGray;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            .message-offer-text {
                width: 100%;
                margin: 1rem 0;
            }

            a {
                color: $red;

                &:hover {
                    color: $darkGray;
                }
            }

            .sent-by {
                width: 100%;
                text-align: right;
                font-size: 1.5rem;
                color: $gray;
            }
        }
        .message-delete {
            color: $red;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 1.6rem;
            text-align: right;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-content: center;
            margin-left: 2rem;
            margin-top: 2rem;
            svg {
                width: 15px;
                margin-right: 1rem;
                path {
                    fill: $red;
                }
            }
        }
    }
    a.back-button {
        color: $blue;
        font-size: 1.5rem;
        text-transform: uppercase;
        font-weight: bold;
        @include transition;
        &:hover {
            color: $gray;

            svg path {
                fill: $gray;
            }
        }
        svg {
            width: 7px;
            path {
                @include transition;
                fill: $blue;
            }
        }
    }
    a.send-reply {
        color: $blue;
        font-size: 1.6rem;
        text-transform: uppercase;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-content: center;
        @include transition;
        margin-bottom: 2rem;
        &:hover {
            color: $gray;

            svg path {
                fill: $gray;
            }
        }
        svg {
            width: 20px;
            margin-right: 1rem;
            path {
                @include transition;
                fill: $blue;
            }
        }
    }
    #map {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;

        h2.map-title {
            color: $blue;
            font-size: 2.2rem;
            margin: 0;
            text-align: Center;
        }
        h3.map-sub-title {
            color: $blue;
            font-size: 1.8rem;
            margin: 0;
            text-align: Center;
        }
        p {
            margin: 0;
            font-weight: bold;
            color: $darkGray;
            font-size: 1.4rem;
        }
        .button.map-button {
            margin-top: 1rem;
            padding: 0;
            font-size: 1.4rem;
            width: 200px;
        }
    }

    .location-content-inner {
        background: $white;
        border-radius: 6px;
        padding: 2rem;
        max-width: 600px;
        margin: 0 auto;
    }

    .location-content,
    .location-content-inner {
        h1.title {
            color: $blue;
            margin: 0;
        }
        .phone-title {
            color: $red;
            left: 50%;
            font-weight: bold;
            margin: 0 auto;
            display: inline-block;
            width: auto;
            transform: translateX(-50%);
            position: relative;
            &:hover {
                color: lighten($red, 10%);
            }
        }
        .address-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;

            a.button {
                align-self: flex-start;
                max-width: 200px;
                margin: 1rem 0 0;
                width: 100%;
                @include breakpoint(640px, up) {
                    align-self: center;
                    margin: 0 auto;
                }
            }
            address {
                font-style: normal;
            }
        }
        h3 {
            color: $blue;
            text-transform: uppercase;
            margin-bottom: 0;
        }
        p {
            color: $darkGray;
            font-weight: bold;
        }
        .store-hours p {
            max-width: 300px;
        }
        .amenities {
            ul {
                list-style-type: disc;
                margin-left: 2rem;
            }
        }
    }
    .filter-container {
        background: $white;
        border-radius: 6px;
        padding: 1rem;
        margin-bottom: 2rem;
        position: relative;
        z-index: 15;
    }
    #mapFilter {
        width: 100%;
        background: $white;
        border: 2px solid $blue;

        #zipCodeMap {
            padding: 0 2rem;
            width: 100%;
            margin-bottom: 2rem;
        }
        form#mapFiltersForm {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 2rem;

            &.hide {
                display: none;
            }
        }

        #filterButton {
            background: $blue;
            text-transform: uppercase;
            width: 100%;
            cursor: pointer;
            padding: 1rem 0;
            color: $white;
            border-radius: 0;
            margin: 0;
            border: none;
            height: auto;

            span {
                display: block;
                width: 100%;
                font-size: 1.4rem;
            }
        }
        .checkboxes {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding: 0 2rem;

            .checkbox {
                margin-right: 2rem;
                label {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.6rem;
                    margin-bottom: 1rem;
                }
                input {
                    margin-right: 1rem;
                }
            }
        }
        .submit {
            margin: 0 auto 2rem;
            display: flex;
            justify-content: center;
            align-self: flex-start;
        }
        #clearFilters {
            align-self: flex-start;
            background: $blue;
            border: 2px solid $blue;
            margin-bottom: 2rem;
            max-width: 220px;
            &.hide {
                display: none;
            }

            &:hover {
                background: $darkGray;
                border: 2px solid $darkGray;
            }
        }
    }
    .offers-container {
        background: $white;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        padding: 1rem 2rem;
        margin: 0 1rem;

        h2 {
            color: $darkGray;
            text-align: left;
            margin-top: 1rem;
            text-transform: uppercase;
            width: 100%;

            @include breakpoint(576px, up) {
                text-align: left;
            }
        }
        .offer-container,
        .offer-container-personalized,
        .manufacturer-link-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: 3rem;
            padding: 0 2rem 0 0;
            width: 100%;
            @include breakpoint(1241px, up) {
                width: 50%;
            }

            .offers-left {
                padding: 0 1rem 0 0;
                width: 50%;

                @include breakpoint(640px, up) {
                    width: auto;
                }

                @include breakpoint(1241px, up) {
                    width: 50%;
                }
                img {
                    max-width: 175px;
                    width: 100%;
                    margin-bottom: 1rem;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                    align-self: flex-start;
                    @include breakpoint(576px, up) {
                        margin-left: 0;
                        margin-right: 0;
                    }
                    @include breakpoint(640px, up) {
                        max-width: 250px;
                    }
                }
            }
            .offers-right {
                padding: 0 0 0 1rem;
                width: 50%;

                @include breakpoint(640px, up) {
                    width: auto;
                }

                @include breakpoint(1241px, up) {
                    width: 50%;
                }
            }
            h3 {
                color: $darkGray;
                font-family: $body-font-family;
                margin: 0 0 0.5rem 0;
                text-align: left;
                width: 100%;
            }
            .details {
                color: $darkGray;
                text-align: left;
                width: 100%;
                @include breakpoint(576px, up) {
                    max-width: 300px;
                }
            }
            .terms,
            .dates-available {
                color: $darkGray;
                font-size: 1.5rem;
                text-align: left;
                width: 100%;
            }
        }
    }
    #offerCategorySelect,
    .offer-category-select {
        max-width: 640px;
        margin: 0 auto;
    }
    .manufacturer-link-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 3rem;
        padding: 0 2rem 0 0;
        width: 100%;
        @include breakpoint(640px, up) {
            width: 50% !important;
        }

        @include breakpoint(1000px, up) {
            width: 33.3333% !important;
        }
        @include breakpoint(1241px, up) {
            width: 25% !important;
        }
        img {
            max-width: 200px;
            width: 100%;
            margin-bottom: 0.5rem;
            display: block;
            align-self: flex-start;
        }
        span {
            max-width: 200px;
            text-align: center;
            display: block;
        }
    }
}
.offers .offers-container {
    a {
        width: 100%;
        color: $red;
        margin-bottom: 1rem;
        @include transition;
        &:hover {
            color: darken($red, 10%);
            svg path {
                fill: darken($red, 10%);
            }
        }
    }
    svg.externalLinkIcon {
        width: 15px;
        height: 15px;
        path {
            fill: $red;
            @include transition;
        }
    }
}
.preferences #appView {
    .container {
        max-width: 1000px;
        padding: 0;
        color: $white;
        min-height: 100vh;
    }
    .button {
        margin-left: 0;
        margin-right: 0;
    }
    h2.subtitle {
        text-align: left;
    }
    .register-pairs {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        flex-wrap: wrap;
        @include breakpoint(640px, up) {
            flex-wrap: nowrap;
        }
        .mui-input-group,
        .select {
            width: 100%;
            max-width: 640px;
            margin: 0;
            padding-right: 0;

            @include breakpoint(640px, up) {
                padding-right: 2rem;
            }
        }
    }
    .switch {
        margin-right: 2rem;
    }

    .preference-container {
        margin-top: 2rem;
        .input.select {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            color: $white;
            width: 100%;

            > label {
                display: none;
            }

            #product-classes-error {
                width: 100%;
            }

            .checkbox {
                margin-right: 3rem;
                width: 100%;
                label {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;
                    input {
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
    .switch-container {
        display: flex;
        justify-content: space-between;
        margin: 1rem 0;

        .switch-label {
            label {
                font-family: $header-font-family;
                color: $white;
                margin-bottom: 0;
            }
            p {
                color: $white;
            }
        }
    }

    .submit {
        margin: 4rem 0 2rem;
        display: flex;
        align-self: flex-start;
    }
    p.disclaimer {
        margin-bottom: 2rem;
    }
}
.privacypolicy,
.ageverification {
    h1,
    h2,
    h3,
    h4,
    h5,
    p {
        color: $white;
    }
    a {
        color: $white;

        &:hover {
            color: darken($white, 10%);
        }
    }
    ul {
        list-style-type: disc;
        li {
            color: $white;
            margin-left: 2rem;
            ul {
                list-style-type: default;
            }
        }
    }
    p {
        margin-bottom: 2rem;
    }
}
.ageverification {
    h3 {
        text-align: left;
        max-width: 700px;
    }
    label {
        text-align: left;
    }
    .register-pairs {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
        @include breakpoint(640px, up) {
            flex-wrap: nowrap;
        }
        .mui-input-group,
        .select {
            width: 100%;
            max-width: 640px;
            margin: 0;
            padding-right: 0;

            @include breakpoint(640px, up) {
                padding-right: 2rem;
            }
        }
    }
    h4 {
        text-align: left;
        margin: 0 0 1.2rem 0;
    }
}
p.pull-refresh {
    color: $white;
    text-align: center;
    margin: 0 0 2rem 0;

    &.margin-top {
        margin: 2rem 0;
    }
}
.ptr--icon,
.ptr--text {
    color: $white !important;
}
.ptr--ptr {
    box-shadow: none !important;
}

input[type="date"],
input[type="time"] {
    -webkit-appearance: none;
}

body.iso #appView .content {
    min-height: calc(100vh - 140px);
}

.preferences #appView .message {
    margin-top: 2rem !important;
}

.button-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    justify-content: flex-end;
    flex-wrap: wrap;

    @include breakpoint(640px, up) {
        flex-wrap: nowrap;
    }
    .button {
        margin: 0 0 0 1rem !important;
        max-width: 150px;
        padding: 0 0.5rem !important;
        font-size: 1.4rem;
        margin-top: 1rem !important;
        @include breakpoint(640px, up) {
            font-size: 1.6rem;
            max-width: 200px;
            padding: 0.5rem 2rem 0.5rem !important;
        }
        &.gray {
            background: #606c76 !important;

            &:hover {
                background: #ed1c24 !important;
            }
        }
    }
}
