.authentication {
    .app-view {
        max-width: 640px;
        header {
            max-width: 640px;
            width: 100%;
            margin: 0 auto;

            #logo {
                max-width: 300px;
                width: 100%;
                display: block;
                margin: 4rem auto 0;

                @include breakpoint(640px, up) {
                    max-width: 640px;
                }

                img {
                    max-width: 95%;
                    margin: 0 auto;
                    display: block;
                }
            }
        }
        .content {
            background: none;
            box-shadow: none;
            padding: 0 2rem;
        }
        p {
            color: $white;
        }

        .message.success {
            background: #e3fcec;
            color: #1f9d55;
            border-color: #51d88a;
            margin: 2rem;

            p {
                color: #1f9d55;
            }
        }
        .message.warning {
            background: #fffabc;
            color: #8d7b00;
            border-color: #d3b800;
            margin: 2rem;

            p {
                color: #8d7b00;
                margin-bottom: 1rem;
            }
            a {
                color: $blue;

                &:hover {
                    color: $darkGray;
                }
            }
        }
        .message.error {
            background: #fcebea;
            color: #cc1f1a;
            border-color: #ef5753;
            margin: 2rem;

            p {
                color: #cc1f1a;
            }
        }
        .message.hint {
            background: #eff8ff;
            color: #2779bd;
            border-color: #6cb2eb;
            margin: 2rem;

            p {
                color: #2779bd;
            }
        }
        input[type="date"],
        input[type="time"] {
            -webkit-appearance: none !important;
            color: $black;
            text-align: left;
        }
        input[type="date"]::-webkit-date-and-time-value,
        input[type="time"]::-webkit-date-and-time-value {
            text-align: left !important;
        }

        h1.subtitle {
            color: $gray;
            font-family: $body-font-family;
            font-weight: 400;
            margin: 0;
            text-align: center;
        }
        h1.title {
            color: $white;
            text-align: center;
        }
        h2 {
            color: $white;
            font-weight: 500;
            margin: 0 0 2rem;
            text-align: center;
        }
        p.forgot-password-text {
            color: $white;
            margin-bottom: 2rem;
        }
        .mui-input-group {
            margin: 0 auto;
        }
        input[type="submit"] {
            margin: 0 auto;
        }
        a.forgot-password {
            font-family: $header-font-family;
            font-weight: 300;
            color: $white;
            margin: 3rem auto;
            display: block;
            text-align: center;

            &:hover {
                color: darken($white, 10%);
                @include transition;
            }
        }
        .or-text {
            margin: 0 auto;
            width: 100%;
            max-width: 640px;
            text-align: center;
            color: $white;
        }
        a.button.register {
            margin: 3rem auto 0;
        }
        .register-pairs {
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            @include breakpoint(640px, up) {
                flex-wrap: nowrap;
                justify-content: flex-start;
            }
            width: 100%;
            .mui-input-group,
            .select {
                width: 100%;
                max-width: 640px;
                margin: 0;
                padding-right: 0;

                @include breakpoint(640px, up) {
                    padding-right: 2rem;
                    max-width: 50%;
                }
            }
        }
    }
    &.register .app-view {
        max-width: 1000px;
        margin: 0 auto;

        .preference-container {
            margin-top: 2rem;
            .input.select {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                color: $white;
                width: 100%;

                > label {
                    display: none;
                }

                #product-classes-error {
                    width: 100%;
                }

                .checkbox {
                    margin-right: 3rem;
                    width: 100%;
                    label {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: flex-start;
                        input {
                            margin-right: 1rem;
                        }
                    }
                }
            }
        }
        h2.subtitle {
            text-align: left;
            margin-bottom: 0;
        }
        h4 {
            color: $white;
            text-align: left;
            margin-top: 1rem;
        }
        .switch {
            margin-right: 2rem;
        }

        .switch-container {
            display: flex;
            justify-content: space-between;
            margin: 1rem 0;

            .switch-label {
                label {
                    font-family: $header-font-family;
                    color: $white;
                    margin-bottom: 0;
                }
                p {
                    color: $white;
                }
            }
        }
    }
}

.forgotpassword a.back-button,
.authentication a.back-button {
    color: $white;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: bold;
    @include transition;
    &:hover {
        color: $gray;

        svg path {
            fill: $gray;
        }
    }
    svg {
        width: 7px;
        path {
            @include transition;
            fill: $white;
        }
    }
}
