/* Utility */
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.content {
    padding: 2rem;
    background: #ffffff;
    border-radius: 0.4rem;
    box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.1),
        0 3px 6px 0 rgba(0, 0, 0, 0.07);
}
.actions a {
    font-weight: bold;
    padding: 0 0.4rem;
}
th {
    white-space: nowrap;
}

/* Nav bar */
.side-nav-item {
    display: block;
    padding: 0.5rem 0;
}

/* View action */
.view.content .text {
    margin-top: 1.2rem;
}
.related {
    margin-top: 2rem;
}

/* Flash messages */
.message {
    padding: 1rem;

    background: #eff8ff;
    color: #2779bd;

    border-color: #6cb2eb;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    margin-bottom: 2rem;
}
.message.hidden {
    display: none;
}
.message.success {
    background: #e3fcec;
    color: #1f9d55;
    border-color: #51d88a;
}
.message.warning {
    background: #fffabc;
    color: #8d7b00;
    border-color: #d3b800;
}
.message.error {
    background: #fcebea;
    color: #cc1f1a;
    border-color: #ef5753;
}
.message.hint {
    background: #eff8ff;
    color: #2779bd;
    border-color: #6cb2eb;
}

/* Paginator */
.paginator {
    text-align: right;
}
.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0 0 1rem;
}
.pagination li {
    margin: 0 0.5rem;
}
.prev.disabled a,
.next.disabled a {
    cursor: not-allowed;
    color: #606c76;
}
.asc:after {
    content: " \2193";
}
.desc:after {
    content: " \2191";
}

/* Error */
.error-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
}

@media screen and (max-width: 640px) {
    .top-nav {
        margin: 0 auto;
    }
    .side-nav {
        margin-bottom: 1rem;
    }
    .heading {
        margin-bottom: 1rem;
    }
    .side-nav-item {
        display: inline;
        margin: 0 1.5rem 0 0;
    }
    .asc:after {
        content: " \2192";
    }
    .desc:after {
        content: " \2190";
    }
}
