body.admin-view {
    #mainNav {
        background: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 4rem;
        margin-bottom: 2rem;
        position: relative;
        top: auto;
        height: auto;

        #logo {
            img {
                width: 100%;
                max-width: 320px;
                padding-right: 2rem;
            }
        }
        .mobile-logo {
            display: block;
            max-width: 230px;
            margin: 6rem auto 0;

            @include breakpoint(900px, up) {
                display: none;
            }
        }

        #menu {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-end;

            #mainMenu {
                width: 100%;
                display: none;
                position: relative;
                left: auto;
                top: auto;
                height: auto;
                background: none;
                border-right: none;
                transform: none;
                transition: none;
                overflow: auto;

                @include breakpoint(900px, up) {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-content: flex-start;
                    flex-wrap: wrap;
                }

                span {
                    font-weight: bold;
                    font-size: 18px;
                    width: 100%;
                    display: block;
                    text-align: right;
                }

                ul {
                    list-style-type: none;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;

                    li {
                        padding: 0 0 0 1rem;
                        margin: 0;
                        text-align: center;
                    }
                }
            }
            #adminMenu {
                flex-wrap: wrap;
                margin-bottom: 1rem;
            }
            #mainMenu ul#menu {
                width: 100%;
                li {
                    padding: 0;
                    text-transform: uppercase;
                    font-weight: 500;

                    @include breakpoint(900px, up) {
                        padding: 0 2rem;
                    }

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
    }
    button#toggleMenu {
        @include breakpoint(900px, up) {
            display: none;
        }
        border: none;
        background: none;
        width: 35px;
        height: 35px;
        padding: 0;
        margin: 0;
        position: relative;
        z-index: 101;
        svg {
            width: 100%;
            height: 100%;
            path {
                fill: $darkBlue;
                @include transition();
            }

            &:hover path {
                fill: $darkGray;
            }

            &.closeIcon {
                display: none;
            }
            &.hamburgerIcon {
                display: block;
            }
        }
    }
    &.menu-active {
        #mainNav #menu #mainMenu {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-content: center;
            flex-wrap: wrap;
            background: $darkBlue;
            color: $white;
            width: 100%;
            min-height: 100%;
            z-index: 100;
            padding-top: 4rem;

            #adminMenu {
                margin-bottom: 4rem;
                span {
                    text-align: center;
                    margin-bottom: 1rem;
                }
                ul {
                    justify-content: center;
                }
            }
            #menu {
                flex-direction: column;
                align-items: center;

                li {
                    margin-bottom: 2rem;
                }
            }

            a {
                color: $white;

                &:hover,
                &:focus,
                &:active {
                    color: $gray;
                }
            }
        }
        button#toggleMenu svg.hamburgerIcon {
            display: none;
        }
        button#toggleMenu svg.closeIcon {
            display: block;

            path {
                fill: $white;
                &:hover {
                    fill: $gray;
                }
            }
        }
    }
}
