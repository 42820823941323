input[type="submit"],
.button {
    display: block;
    font-size: 1.6rem;
    padding: 0.5rem 8rem 0.5rem;
    height: auto;
    @include transition;
    border-radius: 20px;
    background: $red;
    color: $white;
    border: 2px solid $red;
    width: 100%;
    max-width: 320px;

    &:hover {
        background: $darkGray;
        border: 2px solid $darkGray;
    }

    &.gray {
        background: $darkGray;
        border: 2px solid $darkGray;

        &:hover {
            background: $red;
            border: 2px solid $red;
        }
    }
}

.sgmtGrp {
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0; // Prevents "inline-block" attribute of buttons (segmentedButton) to generate unwanted spaces between them. Comment this line to see by yourself.
    margin: 1rem auto;
    overflow: hidden;
    background-color: lighten(black, 80%);
    background-color: white;
    border-radius: 1rem;
    border: 1px solid lighten(black, 80%);
}

.sgmtBtn {
    background: none;
    border: none;
    display: inline-block;
    font-size: 1.6rem;
    line-height: 1rem;
    text-transform: uppercase;
    color: $blue;
    margin-bottom: 0;
    @include transition;

    a {
        display: block;
        padding: 1.5rem 2rem;
    }

    &:nth-child(even) {
        border-left: 1px solid lighten($black, 80%);
        &:not(:last-child) {
            border-right: 1px solid lighten($black, 80%);
        }
    }
    &:hover {
        background-color: $red;
        color: $white;

        a {
            color: $white;
        }
    }
    &.active {
        background-color: $red;
        color: $white;
        a {
            color: $white;
        }
    }
}
