/*------------------------------------
 FORMS
------------------------------------*/
div.mui-input-group {
    margin-bottom: 1.2rem;
    position: relative;
    width: 100%;
    max-width: 400px;

    svg {
        width: 20px;
        height: 20px;
    }
}
span.required {
    color: $red;
}
label.mui-input-underlined {
    font-size: 1.75rem;
}
textarea {
    background: $white;
    min-height: 160px;
}
label.mui-input-underlined > input,
label.mui-input-filled > input,
label.mui-input-underlined > textarea,
label.mui-input-filled > textarea {
    border-bottom: 0.125rem solid $black;
    width: 100%;
    height: 60px;
    font-size: 1.75rem;
    line-height: 147.6%;
    padding-top: 2.5rem;
    padding-bottom: 1rem;
    padding-left: 4.6rem;
}

.mui-input-underlined > input:focus,
.mui-input-filled > input:focus,
.mui-input-underlined > textarea:focus,
.mui-input-filled > textarea:focus {
    outline: none;
}

.mui-input-underlined > .mui-input-label,
.mui-input-filled > .mui-input-label {
    position: absolute;
    top: 20px;
    left: 4.6rem;
    line-height: 147.6%;
    color: $darkGray;
    transition: all 0.2s;
}

.mui-input-underlined > svg,
.mui-input-filled > svg {
    position: absolute;
    top: 0.9375rem;
    left: 1.5rem;
    fill: $blue;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.mui-input-underlined > .mui-input-helper,
.mui-input-filled > .mui-input-helper {
    font-size: 0.9375rem;
    color: $darkGray;
    letter-spacing: 0.0275rem;
    margin: 0.125rem 0.875rem;
}

.mui-input-underlined > input:hover,
.mui-input-filled > input:hover,
.mui-input-underlined > textarea:hover,
.mui-input-filled > textarea:hover {
    background: $white;
    border-color: $black;
}

.mui-input-underlined > input:focus + .mui-input-label,
.mui-input-underlined > input:valid + .mui-input-label,
.mui-input-filled > input:focus + .mui-input-label,
.mui-input-filled > input:valid + .mui-input-label,
.mui-input-underlined > textarea:focus + .mui-input-label,
.mui-input-underlined > textarea:valid + .mui-input-label,
.mui-input-filled > textarea:focus + .mui-input-label,
.mui-input-filled > textarea:valid + .mui-input-label,
.mui-input-group.date .mui-input-label {
    top: 2px;
    font-size: 1.5rem;
    padding-top: 5px;
    width: calc(100% - 5rem);
    background: $white;
}

.mui-input-underlined:not(.mui-input-danger) > input:focus + .mui-input-label,
.mui-input-filled:not(.mui-input-danger) > input:focus + .mui-input-label,
.mui-input-underlined:not(.mui-input-danger)
    > textarea:focus
    + .mui-input-label,
.mui-input-filled:not(.mui-input-danger) > textarea:focus + .mui-input-label {
    color: $darkGray;
}

.mui-input-underlined:not(.mui-input-danger) > input:focus,
.mui-input-filled:not(.mui-input-danger) > input:focus,
.mui-input-underlined:not(.mui-input-danger) > textarea:focus,
.mui-input-filled:not(.mui-input-danger) > textarea:focus {
    border-color: $black;
}

.mui-input-underlined:not(.mui-input-danger) > input:focus ~ svg,
.mui-input-filled:not(.mui-input-danger) > input:focus ~ svg,
.mui-input-underlined:not(.mui-input-danger) > textarea:focus ~ svg,
.mui-input-filled:not(.mui-input-danger) > textarea:focus ~ svg {
    fill: $blue;
}

/** DISABLED **/

.mui-input-underlined > input:disabled,
.mui-input-underlined > textarea:disabled {
    background: $blue;
    cursor: not-allowed;
}

.mui-input-underlined > input:disabled + .mui-input-label,
.mui-input-underlined > input:disabled ~ .mui-input-helper,
.mui-input-underlined > textarea:disabled + .mui-input-label,
.mui-input-underlined > textarea:disabled ~ .mui-input-helper {
    color: $blue;
}

.mui-input-underlined > input:disabled ~ svg,
.mui-input-underlined > textarea:disabled ~ svg {
    fill: $blue;
}

/** DANGER **/

.mui-input-underlined.mui-input-danger > .mui-input-label,
.mui-input-underlined.mui-input-danger > .mui-input-helper,
.mui-input-filled.mui-input-danger > .mui-input-label,
.mui-input-filled.mui-input-danger > .mui-input-helper {
    color: $red;
}

.mui-input-danger > svg {
    fill: $red;
}

.mui-input-danger > input {
    border-color: $red;
    color: $red;
    border-bottom: 0.125rem solid $red;
}

.mui-input-underlined > input {
    background: $white;
}
.mui-input-filled > input {
    background: $white;
}

label.mui-input-underlined.mui-input-danger > input,
label.mui-input-filled.mui-input-danger > input,
label.mui-input-underlined.mui-input-danger > textarea,
label.mui-input-filled.mui-input-danger > textarea {
    border-bottom: 0.125rem solid $red;
}

.mui-input-group.no-icon {
    .mui-input-underlined > input:focus + .mui-input-label,
    .mui-input-underlined > input:valid + .mui-input-label,
    .mui-input-filled > input:focus + .mui-input-label,
    .mui-input-filled > input:valid + .mui-input-label,
    .mui-input-underlined > textarea:focus + .mui-input-label,
    .mui-input-underlined > textarea:valid + .mui-input-label,
    .mui-input-filled > textarea:focus + .mui-input-label,
    .mui-input-filled > textarea:valid + .mui-input-label,
    .mui-input-group.date .mui-input-label {
        width: calc(100% - 5rem);
    }

    label.mui-input-underlined > input,
    label.mui-input-filled > input,
    label.mui-input-underlined > textarea,
    label.mui-input-filled > textarea {
        padding-left: 2rem;
    }
    .mui-input-underlined > .mui-input-label,
    .mui-input-filled > .mui-input-label {
        left: 2rem;
    }
}

select {
    background: $white;
    min-height: 58px;
    margin-bottom: 2rem;
    color: $black;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
    input[type="checkbox"],
    input[type="radio"] {
        --active: #ed1c24;
        --active-inner: #fff;
        --focus: 2px rgba(237, 28, 36, 0.3);
        --border: #bbc1e1;
        --border-hover: #ed1c24ed;
        --background: #fff;
        --disabled: #f6f8ff;
        --disabled-inner: #e1e6f9;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 21px;
        outline: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 0;
        cursor: pointer;
        border: 1px solid var(--bc, var(--border));
        background: var(--b, var(--background));
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
        &:after {
            content: "";
            display: block;
            left: 0;
            top: 0;
            position: absolute;
            transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
                opacity var(--d-o, 0.2s);
        }
        &:checked {
            --b: var(--active);
            --bc: var(--active);
            --d-o: 0.3s;
            --d-t: 0.6s;
            --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
        }
        &:disabled {
            --b: var(--disabled);
            cursor: not-allowed;
            opacity: 0.9;
            &:checked {
                --b: var(--disabled-inner);
                --bc: var(--border);
            }
            & + label {
                cursor: not-allowed;
            }
        }
        &:hover {
            &:not(:checked) {
                &:not(:disabled) {
                    --bc: var(--border-hover);
                }
            }
        }
        &:focus {
            box-shadow: 0 0 0 var(--focus);
        }
        &:not(.switch) {
            width: 21px;
            &:after {
                opacity: var(--o, 0);
            }
            &:checked {
                --o: 1;
            }
        }
        & + label {
            font-size: 14px;
            line-height: 21px;
            display: inline-block;
            vertical-align: top;
            cursor: pointer;
            margin-left: 4px;
        }
    }
    input[type="checkbox"] {
        &:not(.switch) {
            border-radius: 7px;
            &:after {
                width: 5px;
                height: 9px;
                border: 2px solid var(--active-inner);
                border-top: 0;
                border-left: 0;
                left: 7px;
                top: 4px;
                transform: rotate(var(--r, 20deg));
            }
            &:checked {
                --r: 43deg;
            }
        }
        &.switch {
            width: 38px;
            border-radius: 11px;
            &:after {
                left: 2px;
                top: 2px;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                background: var(--ab, var(--border));
                transform: translateX(var(--x, 0));
            }
            &:checked {
                --ab: var(--active-inner);
                --x: 17px;
            }
            &:disabled {
                &:not(:checked) {
                    &:after {
                        opacity: 0.6;
                    }
                }
            }
        }
    }
    input[type="radio"] {
        border-radius: 50%;
        &:after {
            width: 19px;
            height: 19px;
            border-radius: 50%;
            background: var(--active-inner);
            opacity: 0;
            transform: scale(var(--s, 0.7));
        }
        &:checked {
            --s: 0.5;
        }
    }
}
.error-message {
    margin-top: 1rem;
    color: $red;
    border: 2px solid #ef5753;
    background: #fcebea;
    padding: 1rem;
}

textarea.form-error,
textarea:hover.form-error {
    border: 2px solid #ef5753;
}

.users.register.authentication .app-view .register-pairs {
    #emailaddress,
    .register-email {
        width: 100%;
        max-width: 100%;
    }
}

#showPassword {
    width: 100%;
    display: block;
    button {
        width: 100%;
        display: inline;
        margin: 0;
        padding: 0;
        background: none;
        color: $white;
        border: none;
        @include transition();

        &:hover {
            color: $lightGray;

            svg path {
                fill: $lightGray;
            }
        }

        svg {
            height: 22px;
            margin-right: 1rem;
            path {
                @include transition();
                fill: $white;
            }
        }
    }
    .password-visible {
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: flex-start;
    }
    .password-hidden {
        display: none;
    }

    &.hidden {
        .password-visible {
            display: none;
        }
        .password-hidden {
            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: flex-start;
        }
    }
}

.authentication.users.login .checkbox {
    color: $white;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    label {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        align-content: center;

        input {
            margin-right: 1rem;
        }
    }
}
