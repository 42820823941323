body.admin-view {
    #storeAddressContainer {
        display: none;
    }
    #editStoreLocationAddress {
        margin-top: 1rem;
    }
    h5.store-hours-header {
        margin-top: 0;
        margin-bottom: 1rem;
    }
}
