body.admin-view {
    .offers.view.content {
        .image {
            margin-top: 1.2rem;

            img {
                margin-bottom: 0;
            }
        }
    }
}
