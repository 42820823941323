#mainNav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: $white;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 100;
    height: 60px;

    #mainMenuToggle {
        background: none;
        border: none;
        outline: none;
        width: 35px;
        height: 35px;
        padding: 0;
        margin-left: 2rem;
        svg {
            width: 35px;
            height: 35px;

            &:hover {
                path {
                    fill: $gray;
                }
            }
            path {
                @include transition;
                fill: $blue;
            }
        }
    }
    #logo {
        margin-top: 1rem;
        max-width: 225px;
        img {
            margin-bottom: 0;
        }
    }
    #alertBell {
        width: 28px;
        height: 28px;
        margin-right: 2rem;
        position: relative;
        &:hover {
            path {
                fill: $gray;
            }

            #messageCount {
                @include transition;
                background: lighten($red, 20%);
            }
        }
        path {
            @include transition;
            fill: $blue;
        }

        .message-count {
            background: $red;
            border-radius: 200px;
            font-size: 1.2rem;
            font-weight: bold;
            color: #ffffff;
            width: 85%;
            height: 85%;
            position: absolute;
            top: 0px;
            left: -10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;

            span {
                display: inline-flex;
                width: 100%;
                height: 100%;
                position: relative;
                text-align: center;
                justify-content: center;
                align-items: center;
                padding: 0.5rem;
            }
        }
    }
}

#mainMenu {
    width: 300px;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background: $white;
    border-right: 2px solid $darkGray;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    transition: all 400ms ease-in-out;
    overflow-y: auto;

    #logout {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        color: $blue;
        font-weight: bold;
        text-transform: uppercase;
        margin-right: 2rem;
        margin-top: 3rem;

        &:hover svg path {
            fill: $darkGray;
        }
        svg {
            margin-left: 1rem;
            width: 20px;
            path {
                fill: $blue;
            }
        }
    }
}
#appView {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition: all 400ms ease-in-out;
    min-height: 100vh;
    position: relative;
    flex: 1 0 auto;
}

.body-controller.menu-open {
    #mainMenu {
        visibility: visible;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    #appView {
        -webkit-transform: translate3d(300px, 0, 0);
        transform: translate3d(300px, 0, 0);
    }
}
#appMenu {
    list-style-type: none;
    margin-top: 2rem;
    li {
        margin-left: 3rem;
        margin-bottom: 2.5rem;

        a {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 1.8rem;
            font-family: $body-font-family;
            color: $blue;

            &:hover {
                color: $darkGray;
                svg path {
                    fill: $darkGray;
                }
            }
        }
        &#installApp {
            a {
                display: flex;
                color: $white;
                max-width: 80%;
                justify-content: center;
                padding: 0;
                &:hover {
                    color: $white;
                }
                &.hidden {
                    display: none;
                }
            }
        }

        svg {
            margin-right: 1.5rem;
            path {
                @include transition;
                fill: $blue;
            }
        }
        &.home a svg,
        &.locations a svg,
        &.offers a svg {
            width: 20px;
        }

        &.contact a svg {
            width: 20px;
        }
        &.careers a svg {
            width: 21px;
        }
        &.loyalty-card a svg {
            width: 22px;
        }
        &.messages a svg {
            width: 20px;
        }
        &.preferences a svg {
            width: 22px;
        }
        &.privacy a svg {
            width: 19px;
        }
    }
}

footer#footerMenu {
    padding: 0;
    margin: 0;
    height: 80px;
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
    background: $white;
    z-index: 100;
    padding: 1rem 0.5rem;
    height: 65px;

    @include breakpoint(450px, up) {
        padding: 1rem;
    }

    @include breakpoint(640px, up) {
        padding: 1rem 2rem;
    }
    ul {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        margin: 0;
        justify-content: space-evenly;

        li {
            margin-bottom: 0;
            a {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 1.5rem;
                font-family: $body-font-family;
                color: $darkGray;

                &:hover {
                    color: $blue;
                    svg path {
                        fill: $blue;
                    }
                }
            }
            svg {
                margin: 0 auto 0.5rem;

                path {
                    fill: $darkGray;
                }
            }
            &.home a svg,
            &.locations a svg,
            &.offers a svg {
                width: 25px;
            }

            &.contact a svg {
                width: 20px;
            }
            &.careers a svg {
                width: 21px;
            }
            &.loyalty-card a svg {
                width: 22px;
            }
            &.messages a svg {
                width: 20px;
            }
            &.preferences a svg {
                width: 22px;
            }
            &.privacy a svg {
                width: 19px;
            }
        }
    }
}

body.ios footer#footerMenu {
    height: 80px;
}
