body.admin-view {
    input[type="submit"],
    .button,
    button {
        padding: 1rem 2rem;
        text-transform: uppercase;
        height: auto;
        @include transition;
        border-radius: 20px;
        background: $red;
        color: $white;
        border: 2px solid $red;
        width: auto;
        max-width: 320px;
        display: inline-block;
        margin-right: 1rem;
        line-height: 1.15;
        &:hover {
            background: $darkGray;
            border: 2px solid $darkGray;
        }

        &.gray {
            background: $darkGray;
            border: 2px solid $darkGray;

            &:hover {
                background: $red;
                border: 2px solid $red;
            }
        }
        &.blue {
            background: $blue;
            border: 2px solid $blue;

            &:hover {
                background: $darkGray;
                border: 2px solid $darkGray;
            }
        }
    }
}
